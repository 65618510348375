import React from 'react';
import './Body.css'
export default function About(){


    return(
    <div>
        <div className='.whiteText'>
            Looking for work:
            <ul>
                <li>C++: Unreal Engine 5 (currently developing a multiplayer game)</li>
                <li>Blender: Low-Poly sculpting, texturing, rigging, animating</li>
                <li>JavaScript: React, Game Maker Language</li>
                <li>Python: pandas, matplotlib, scikitlearn</li>
                <li>Clip Studio Paint, Procreate</li>
            </ul>

            I am available for all the teams that love what they do.

            <br />  
            <br />  
            isthisamug@gmail.com
            <br />
      

        </div>
         {/* A group of gooded people that watched my mom and me grow up.
         <br />
         They would knock on my window at night and left knives in our back yard.
         <br />
         I have always considered my ability to live in the confines of my mind a strength.
         <br />
         If you heard an isolated knock at your window you would brush it off. 
         <br />
         Two knocks? Maybe a branch in the wind.
         <br />
         But if you are lying in bed, pretending to sleep and restlessly rolling-
         <br />
         and you hear KNOCK-KNOCK-KNOCK-KNOCK-KNOCK--
         <br />
         There is definitely somebody at the window
         <br />
         What are you supposed to do?
         <br />
         By the time decide to stick a finger between the blinds and peek into your backyard-
         <br />
         There's nobody There
         <br />
         But of course there's nobody there.
         <br />
         Just the trees blowing in the wind.
         <br />
         And the street lamp light reaching over your fence. */}
    </div>
    )
}
